.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.rdt_TableHead .rdt_TableHeadRow {
  background-color: #e8e8e8;
  border-radius: 5px 5px 0 0 !important;
}

.rdt_Table .rdt_TableHead {
  color: gray;
}
.webKit-color div input {
  -webkit-text-fill-color: #000 !important;
}

.input-field div {
  border-radius: 64px;
}
.selec-field div {
  border-radius: 64px !important;
}

.bottom-dialog-submit {
  display: flex !important;
  justify-content: flex-end !important;
}

.upload-labels {
  text-align: center;
  padding: 1rem;
  color: #ed2a4d;
}
.modal-header {
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
  margin-bottom: 10px;
  text-align: center;
}
.modal-submit-button {
  font-weight: 600 !important;
  border-radius: 43px !important;
  background: #ed2a4d !important;
  padding: 17px 40px !important;
}

.page-head-wrapper {
  display: flex;
  flex-direction: column;
}
.page-head-title {
  display: flex;
  justify-content: flex-start;
}
.download-button {
  border-radius: 4px;
  background-color: #fff !important;
  border: none !important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}
.search_ip {
  width: 20rem;
  text-align: center;
}
.search_ip div {
  border-radius: 64px;
  text-align: center;
}
.search_ip input {
  /* text-align: center; */
}
.page-head-filter-wrapper {
  display: flex;
  gap: 10px;
  /* margin-bottom: 20px; */
}
.page-head-create {
  /* color: black!important; */
  /* border: 1px solid black !important; */
  border-radius: 46px !important;
  text-transform: nonementi;
  color: white !important;
  background-color: #ed2a4d !important;
}
.btn-white {
  /* color: black!important; */
  border: 1px solid rgb(255, 255, 255) !important;
  border-radius: 46px !important;
}
.error {
  color: #d32f2f !important;
}
.page-head-create-filter {
  width: 100%;
}
.ip-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.MuiAutocomplete-inputRoot {
  text-transform: capitalize;
}
.MuiAutocomplete-popper li {
  text-transform: capitalize;
}
.MuiInputBase-root.Mui-focused {
  background-color: transparent !important; /* Change this to the desired color */
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: gray !important; /* Change this to your desired color */
}
.input-field input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 100px white inset;
  -webkit-text-fill-color: #000;
}

.input-field input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 100px white inset;
  -webkit-text-fill-color: #000;
}

.input-field input:-webkit-autofill:hover {
  -webkit-box-shadow: 0 0 0 100px white inset;
  -webkit-text-fill-color: #000;
}

.input-field
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: unset; /* Removes the focused border color */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.remarks-field div {
  border-radius: 10px;
}
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ql-tooltip {
  left: 0 !important;
}
